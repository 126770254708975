import * as duiColors from '../../tokens/themes/light/colors'
import * as gdsColors from '../../tokens/gds-colors'
import {
  colorDuxtonBlue,
  colorError,
  colorInfo,
  colorNeutral,
  colorOrange,
  colorPrimary,
  colorTeal,
  colorWarning,
} from '../../colors'

const colors = {
  // Primary/Success
  // Grab Primary and Success is the same Green pallete
  [colorPrimary]: {
    1: duiColors.colorBaseGreen50,
    2: duiColors.colorBaseGreen75,
    3: duiColors.colorBaseGreen100,
    4: duiColors.colorBaseGreen200,
    5: duiColors.colorBaseGreen300,
    6: duiColors.colorBaseGreen400,
    7: duiColors.colorBaseGreen500,
    8: duiColors.colorBaseGreen600,
    9: duiColors.colorBaseGreen700,
    10: duiColors.colorBaseGreen800,
    11: duiColors.colorBaseGreen900,
  },

  // Error
  [colorError]: {
    1: duiColors.colorBaseRed50,
    2: duiColors.colorBaseRed75,
    3: duiColors.colorBaseRed100,
    4: duiColors.colorBaseRed200,
    5: duiColors.colorBaseRed300,
    6: duiColors.colorBaseRed400,
    7: duiColors.colorBaseRed500,
    8: duiColors.colorBaseRed600,
    9: duiColors.colorBaseRed700,
    10: duiColors.colorBaseRed800,
    11: duiColors.colorBaseRed900,
  },

  // Warrning
  [colorWarning]: {
    1: duiColors.colorBaseYellow50,
    2: duiColors.colorBaseYellow75,
    3: duiColors.colorBaseYellow100,
    4: duiColors.colorBaseYellow150,
    5: duiColors.colorBaseYellow200,
    6: duiColors.colorBaseYellow250,
    7: duiColors.colorBaseYellow300,
    8: duiColors.colorBaseYellow350,
    9: duiColors.colorBaseYellow400,
    10: duiColors.colorBaseYellow500,
    11: duiColors.colorBaseYellow600,
  },

  // Info/Link
  // Grab Info and Link using same Blue pallete
  [colorInfo]: {
    1: duiColors.colorBaseBlue50,
    2: duiColors.colorBaseBlue75,
    3: duiColors.colorBaseBlue100,
    4: duiColors.colorBaseBlue200,
    5: duiColors.colorBaseBlue300,
    6: duiColors.colorBaseBlue400,
    7: duiColors.colorBaseBlue500,
    8: duiColors.colorBaseBlue600,
    9: duiColors.colorBaseBlue700,
    10: duiColors.colorBaseBlue800,
    11: duiColors.colorBaseBlue900,
  },

  // Neutral
  [colorNeutral]: {
    1: duiColors.colorBaseNeutral50,
    2: duiColors.colorBaseNeutral75,
    3: duiColors.colorBaseNeutral100,
    4: duiColors.colorBaseNeutral200,
    5: duiColors.colorBaseNeutral300,
    6: duiColors.colorBaseNeutral400,
    7: duiColors.colorBaseNeutral500,
    8: duiColors.colorBaseNeutral600,
    9: duiColors.colorBaseNeutral700,
    10: duiColors.colorBaseNeutral800,
    11: duiColors.colorBaseNeutral900,
  },

  // Orange
  [colorOrange]: {
    1: duiColors.colorBaseOrange50,
    2: duiColors.colorBaseOrange75,
    3: duiColors.colorBaseOrange100,
    4: duiColors.colorBaseOrange200,
    5: duiColors.colorBaseOrange300,
    6: duiColors.colorBaseOrange400,
    7: duiColors.colorBaseOrange500,
    8: duiColors.colorBaseOrange600,
    9: duiColors.colorBaseOrange700,
    10: duiColors.colorBaseOrange800,
    11: duiColors.colorBaseOrange900,
  },

  // Cyan/teal
  [colorTeal]: {
    1: gdsColors.gdsColorIntegrityTeal50,
    2: gdsColors.gdsColorIntegrityTeal75,
    3: gdsColors.gdsColorIntegrityTeal100,
    4: gdsColors.gdsColorIntegrityTeal200,
    5: gdsColors.gdsColorIntegrityTeal300,
    6: gdsColors.gdsColorIntegrityTeal400,
    7: gdsColors.gdsColorIntegrityTeal500,
    8: gdsColors.gdsColorIntegrityTeal600,
    9: gdsColors.gdsColorIntegrityTeal700,
    10: gdsColors.gdsColorIntegrityTeal800,
    11: gdsColors.gdsColorIntegrityTeal900,
  },

  // Duxton Blue/Geek Blue
  [colorDuxtonBlue]: {
    1: gdsColors.gdsColorDuxtonBlue50,
    2: gdsColors.gdsColorDuxtonBlue75,
    3: gdsColors.gdsColorDuxtonBlue100,
    4: gdsColors.gdsColorDuxtonBlue200,
    5: gdsColors.gdsColorDuxtonBlue300,
    6: gdsColors.gdsColorDuxtonBlue400,
    7: gdsColors.gdsColorDuxtonBlue500,
    8: gdsColors.gdsColorDuxtonBlue600,
    9: gdsColors.gdsColorDuxtonBlue700,
    10: gdsColors.gdsColorDuxtonBlue800,
    11: gdsColors.gdsColorDuxtonBlue900,
  },
}

export const generateColorPalettesDefault = (baseColor: string) => {
  return colors[baseColor]
}
