// https://github.com/ant-design/ant-design-colors/blob/main/src/index.ts
import {
  gdsColorBlue500,
  gdsColorDuxtonBlue200,
  gdsColorGreen400,
  gdsColorGreen700,
  gdsColorIntegrityTeal200,
  gdsColorNeutral100,
  gdsColorNeutralWhite,
  gdsColorOrange400,
  gdsColorRed500,
  gdsColorYellow300,
} from '../tokens/gds-colors'
import generate from './generate'

const presetPrimaryColors: Record<string, string> = {
  red: gdsColorRed500,
  volcano: '#FA541C', // Dui: deprecating
  orange: gdsColorOrange400,
  gold: '#FAAD14', // Dui: deprecating
  yellow: gdsColorYellow300,
  lime: '#A0D911', // Dui: deprecating
  green: gdsColorGreen400,
  cyan: gdsColorIntegrityTeal200, // Dui: deprecating
  blue: gdsColorBlue500, // Dui: deprecating
  geekblue: gdsColorDuxtonBlue200, // Dui: deprecating
  purple: '#722ED1',
  magenta: '#EB2F96', // Dui: deprecating
  grey: gdsColorNeutral100,
  white: gdsColorNeutralWhite, // Dui
  darkgreen: gdsColorGreen700, // Dui
}

const presetPalettes: PalettesProps = {}
const presetDarkPalettes: PalettesProps = {}

Object.keys(presetPrimaryColors).forEach((key): void => {
  presetPalettes[key] = generate(presetPrimaryColors[key])
  presetPalettes[key].primary = presetPalettes[key][5]

  // dark presetPalettes
  presetDarkPalettes[key] = generate(presetPrimaryColors[key], {
    theme: 'dark',
    backgroundColor: '#141414',
  })
  presetDarkPalettes[key].primary = presetDarkPalettes[key][5]
})

const red = presetPalettes.red
const volcano = presetPalettes.volcano
const gold = presetPalettes.gold
const orange = presetPalettes.orange
const yellow = presetPalettes.yellow
const lime = presetPalettes.lime
const green = presetPalettes.green
const cyan = presetPalettes.cyan
const blue = presetPalettes.blue
const geekblue = presetPalettes.geekblue
const purple = presetPalettes.purple
const magenta = presetPalettes.magenta
const grey = presetPalettes.grey
const gray = presetPalettes.grey
const darkgreen = presetPalettes.darkgreen // Dui
const teal = presetPalettes.cyan // Dui

export type PalettesProps = Record<string, string[] & { primary?: string }>

export {
  generate,
  presetPalettes,
  presetDarkPalettes,
  presetPrimaryColors,
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
  gray,
  darkgreen, // Dui
  teal, // Dui
}
