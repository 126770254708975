import {
  colorBackgroundTranslucentInverse,
  colorBaseNeutralWhite,
  colorBaseOrange400,
  colorBrandPrimaryDefault,
  colorContentTextLinkDefault,
  colorStatusAlertDefault,
  colorStatusInformativeDefault,
  colorStatusNeutralDefault,
  colorStatusNoticeDefault,
  colorStatusPositiveDefault,
} from './tokens/themes/light/colors'
import { gdsColorDuxtonBlue200, gdsColorIntegrityTeal200 } from './tokens/gds-colors'

// Seed tokens
export const colorPrimary = colorBrandPrimaryDefault
export const colorSuccess = colorStatusPositiveDefault
export const colorWarning = colorStatusNoticeDefault
export const colorError = colorStatusAlertDefault
export const colorInfo = colorStatusInformativeDefault
export const colorLink = colorContentTextLinkDefault
export const colorNeutral = colorStatusNeutralDefault

// Preset colors
export const colorOrange = colorBaseOrange400
export const colorTeal = gdsColorIntegrityTeal200
export const colorDuxtonBlue = gdsColorDuxtonBlue200

// Color map tokens
export const colorBgMask = colorBackgroundTranslucentInverse
export const colorWhite = colorBaseNeutralWhite
